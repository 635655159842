<template>
  <v-responsive :width="width">
    <v-form @submit.prevent="doSearch" autocomplete="off">
      <v-text-field
        :disabled="searchInProgress"
        ref="textInput"
        type="text"
        :density="density"
        hide-details
        required
        bg-color="white"
        :color="color"
        class="search-input"
        :class="searchClass"
        :placeholder="null"
        :variant="variant"
        v-model="searchQueryReactive"
        @click:prepend-inner="doSearch"
        @keydown.enter.prevent="doSearch()"
      >
        <template v-slot:prepend-inner>
          <v-btn
            readonly="true"
            v-if="!searchQueryReactive"
            class="p-1"
            icon="mdi-magnify"
            size="x-small"
            variant="flat"
            rounded="lg"
            color="button-primary"
          ></v-btn>
          <v-btn
            v-if="searchQueryReactive"
            class="p-1"
            icon="mdi-close"
            size="x-small"
            variant="flat"
            rounded="lg"
            @click="clearInput()"
          ></v-btn>
        </template>

        <template v-slot:label>
          <span :class="searchClass">{{ searchLabel }}</span>
        </template>

        <template v-slot:loader>
          <v-progress-linear
            :active="searchInProgress"
            :color="contrast"
            :model-value="searchInProgress"
            height="3"
            indeterminate
          ></v-progress-linear>
        </template>

        <template v-slot:append-inner>
          <div
            class="d-flex align-items-center bg-contrast-lighten-2 border-thin rounded-lg search-button-container"
          >
            <v-btn
              v-bind="props"
              class="search-button-style"
              @on="on"
              variant="flat"
              size="small"
              color="button-primary"
              :title="searchScopeComputed"
              @click.prevent="doSearch()"
            >
              <span class="limit-text">{{
                $t("legenda_079", [searchScopeComputed])
              }}</span>
            </v-btn>

            <v-menu
              ref="scopeMenu"
              transition="slide-y-transition"
              :close-on-content-click="false"
              location="bottom"
              style="font-size: small"
              v-model="menu"
              stacked
            >
              <template v-slot:activator="{ on, props }">
                <v-btn
                  v-bind="props"
                  size="small"
                  min-width="25"
                  class="px-2 search-scope-style"
                  @on="on"
                  variant="flat"
                  color="primary-darken-1"
                >
                  <v-icon class="p-0">mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-sheet class="p-1" color="white">
                <treeView @newScope="changeScope" />
              </v-sheet>
            </v-menu>
          </div>
        </template>
      </v-text-field>
    </v-form>
  </v-responsive>
</template>

<script>
import "vue3-tree-vue/dist/style.css";
import TreeView from "../TreeView.vue";
export default {
  components: { treeView: TreeView },
  name: "SearchInputComponent",
  props: {
    variant: {
      type: String,
      default: "outlined",
    },
    density: {
      type: String,
      default: "default",
    },
    color: {
      type: String,
      default: "input-primary",
    },
    label: {
      type: String,
      default: "legenda_001",
    },
    width: {
      type: Number,
      default: 700,
    },
    searchScope: {
      type: Array,
      default() {
        return [];
      },
    },
    searchQuery: {
      type: String,
      default: null,
    },
    searchInProgress: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    searchLabel() {
      if (!this.searchQueryReactive) return this.$t(this.label);

      return this.searchMode === "semantic"
        ? this.$t("legenda_003")
        : this.$t("legenda_002");
    },
    searchClass() {
      if (!this.searchQueryReactive) return "ai-search";
      const styleClass = this.rules.semantic(this.searchQueryReactive)
        ? "ai-search"
        : "keyword-search";
      return styleClass;
    },

    searchScopeComputed: {
      // getter
      get() {
        return this.searchScopeText;
      },
      // setter
      set(newValue) {
        // Note: we are using destructuring assignment syntax here.
        this.searchScopeText = newValue;
      },
    },
  },
  watch: {
    searchQueryReactive(newValue) {
      this.searchMode = newValue
        ? this.rules.semantic(newValue)
          ? "semantic"
          : "keyword"
        : null;
      if (!newValue) {
        this.$store.commit("setSearchQueryObject", {
          searchQuery: null,
          searchMode: null,
        });
      }
    },
    searchScope(newValue) {
      if (newValue) {
        this.searchScopeComputed = newValue[0].name;
      }
    },
  },
  data() {
    return {
      menu: false,
      searchQueryReactive: null,
      searchMode: null,
      searchScopeText: null,
      rules: {
        semantic: (value) => {
          const words = value.split(" ");
          return words.length >= 4;
        },
      },
    };
  },
  methods: {
    /**
     *
     */
    doSearch() {
      this.$emit("doSearch", {
        searchQuery: this.searchQueryReactive,
        searchMode: this.searchMode,
      });
    },
    /*
     */
    clearInput() {
      this.$store.commit("setSearchQueryObject", {
        searchQuery: null,
        searchMode: null,
      });

      this.searchQueryReactive = null;
    },
    /**
     *
     * @param {*} item
     */
    changeScope(item) {
      // change selected
      this.menu = false;
      this.searchScopeComputed = item.name;

      this.$emit("changeScope", item);
    },
  },
  created() {
    this.searchQueryReactive = this.searchQuery;
  },
};
</script>

<style>
.search-button-container {
  column-gap: 1px;
}
.search-button-style {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.search-scope-style {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

@media (max-width: 600px) {
  .search-input .v-input__control .v-field {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding-bottom: 12px;
  }

  .search-input .v-field__prepend-inner {
    position: absolute;
    z-index: 1;
    top: 8px;
  }

  .search-input .v-field__field {
    margin-left: 32px;
  }

  .search-input .v-field__append-inner {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .search-input .v-field__append-inner button {
    width: 100% !important;
  }
  .search-button-container {
    row-gap: 1px;
    flex-wrap: wrap;
    width: 100%;
  }
  .search-button-style {
    border-top-right-radius: inherit !important;
    border-bottom-left-radius: 0px !important;
  }
  .search-scope-style {
    border-top-right-radius: 0 !important;
    border-bottom-left-radius: inherit !important;
  }
}

input:focus {
  outline: none;
}

.search-input {
  border: none;
  margin: auto;
  width: 100%;
  padding: 0.5%;
  margin-top: 5px;
}
.keyword-search {
  color: #b2afa8;
}
.ai-search {
  color: #75736e;
}
.limit-text {
  display: block;
  width: 170px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
